import React from 'react';
import { Link } from 'react-router-dom';
import { BackButton } from '../../components/BackButton';
import { Footer } from '../../components/Footer';
import { Layout } from '../../components/Layout';

// import { Container } from './styles';

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Layout>
        <BackButton valueText="Voltar" closeButton />
        <div style={{ marginBottom: '-20px', paddingBottom: '100px' }}>
          <h2>
            <strong>Política de Privacidade</strong>
          </h2>
          <p>
            <strong>1. Sobre a política</strong>
          </p>
          <p>
            1.1. Esta Política de Privacidade tem por finalidade demonstrar o
            compromisso do Instituto da Criança, inscrito no CNPJ:
            02.744.697/0001-30, localizado na Rua Faro 80, - anexo 2° andar -
            Jardim Botânico, Rio de Janeiro, RJ com a privacidade e a proteção
            dos dados pessoais coletados de seus USUÁRIOS, estabelecendo as
            regras sobre a coleta, registro, armazenamento, uso,
            compartilhamento, de acordo com as leis em vigor.
          </p>
          <p>
            1.2. O Instituto da Criança é responsável pela administração do
            domínio do hotsite “Doar Felicidade” e essa Política de Privacidade
            se aplica a esse canal.
          </p>
          <p>
            1.3. O hotsite “Doar Felicidade” trata de uma campanha de doações de
            presentes de natal para crianças ligadas a instituições parceiras do
            Instituto da Criança.
          </p>
          <p>
            1.4. Como condição para acesso e uso das funcionalidades exclusivas
            do hotsite “Doar Felicidade”, o USUÁRIO declara ser maior de 18
            (dezoito) anos e que fez a leitura completa e atenta das regras
            deste documento e dos Termos de Uso, estando plenamente ciente
            conferindo assim sua livre e expressa concordância com os termos
            aqui estipulados. Caso não esteja de acordo com estas Diretivas,
            deverá descontinuar o seu acesso.
          </p>
          <p>
            <strong>2. Quem nós somos</strong>
          </p>
          <p>
            2.1. O Instituto da Criança é uma solução para promover o
            desenvolvimento humano. Por meio do investimento social privado e da
            gestão de projetos, a organização inspira a prática da
            solidariedade.
          </p>
          <p>
            2.2. Com 28 anos de história, o Instituto funciona como uma via de
            aproximação entre pessoas físicas e jurídicas que têm condições e
            vontade de contribuir, contudo não sabem como fazer este
            investimento chegar a quem realmente precisa.
          </p>
          <p>
            2.3. Desta forma, a instituição conecta e articula esses dois
            grupos, assessorando empresas e direcionando recursos financeiros,
            humanos, materiais e conhecimentos técnicos a fim de promover o
            desenvolvimento social. Anualmente, investe em projetos e campanhas
            de educação, cidadania, geração de renda e desenvolvimento
            comunitário.
          </p>
          <p>
            <strong>
              3. Notificação de Alteração de Política de Privacidade:
            </strong>
          </p>
          <p>
            3.1. Esta Política de Privacidade pode mudar de tempos em tempos. O
            USUÁRIO deverá entrar nesta página da Web para manter-se atualizado
            sobre as alterações em nossa Política de Privacidade.
          </p>
          <p>
            <strong>4. </strong>
            <strong>Consentimento do cookies:</strong>
          </p>
          <p>
            4.1. Ao usar nosso hotsite, assinar nossa lista de e-mails, fazer
            doações para o Instituto e/ou solicitar informações, o USUÁRIO, a
            menos que tenha configurado o computador navegador para rejeitá-los,
            concorda com os cookies estabelecidos abaixo e com o uso de dados
            associados de acordo com esta política.
          </p>
          <p>
            <strong>5. Como coletamos as informações?</strong>
            <strong>&nbsp;</strong>
          </p>
          <p>
            5.1. Podemos coletar informações sobre USUÁRIO quando eles interagem
            conosco. Por exemplo, quando o USUÁRIO entra em contato com o
            Instituto da Criança, envia ou recebe informações de ou para a
            organização, registra-se em nosso boletim informativo, se envolve
            com nossas mídias sociais ou faz uma doação, o USUÁRIO pode nos
            fornecer suas informações pessoais.
          </p>
          <p>
            5.2. Também podemos receber informações sobre o USUÁRIO quando ele
            interage com terceiros com quem trabalhamos. Por exemplo, podemos
            receber informações sobre o USUÁRIO, depois que ele fez uma doação
            para a organização através de um site de terceiros. Podemos coletar
            informações sobre o USUÁRIO de fontes publicamente disponíveis
            através de serviços de assinatura de terceiros ou provedores de
            serviços.
          </p>
          <p>
            5.3. Podemos coletar informações agregadas ou anônimas quando o
            USUÁRIO visita nosso site ou interage com nosso conteúdo.
          </p>
          <p>
            <strong>6. Dados Coletados, Forma e Finalidade da Coleta</strong>
          </p>
          <p>
            6.1. Serão coletados apenas o nome completo do USUÁRIO para
            localizar quem deseja presentear uma criança pelo hotsite “Doar
            Felicidade”.
          </p>
          <p>
            6.2. Todas as tecnologias utilizadas respeitarão sempre a legislação
            vigente e os termos desta Política de Privacidade.
          </p>
          <p>
            6.3. O consentimento que o USUÁRIO fornece para as finalidades de
            uso dos dados é coletado de forma individual, clara, específica e
            legítima.
          </p>
          <p>
            6.4. Os dados coletados e as atividades registradas não serão
            compartilhados e serão usados apenas para a finalidade de organizar
            as doações de presentes de natal, durante o período da campanha.
          </p>
          <p>
            6.5. A base de dados formada por meio da coleta de dados é de nossa
            propriedade e responsabilidade, sendo que seu uso, acesso e
            compartilhamento, quando necessários, serão feitos dentro dos
            limites e propósitos dos nossos negócios e descritos nesta Política
            de Privacidade.
          </p>
          <p>
            6.6.&nbsp; Informações de identificação pessoal não serão vendidas
            ou alugadas.
          </p>
          <p>
            6.7.&nbsp; O USUÁRIO é corresponsável pelo sigilo de seus dados
            pessoais.
          </p>
          <p>
            6.8. Não serão enviados e-mails solicitando que o USUÁRIO encaminhe
            seus dados cadastrais.
          </p>
          <p>
            6.9. Internamente, os dados de que coletamos são acessados somente
            por profissionais devidamente autorizados, além do compromisso de
            confidencialidade e preservação da privacidade nos termos desta
            Política de Privacidade.
          </p>
          <p>
            <strong>7. Como usamos as Informações Pessoais?</strong>
          </p>
          <p>
            7.1. Utilizamos apenas os dados pessoais dos USUÁRIOS para os fins
            para os quais os coletamos descritos nessa Política, a menos que
            consideremos, razoavelmente, que precisamos usá-los por outro motivo
            e esse motivo seja compatível com o objetivo original. Se o USUÁRIO
            deseja receber uma explicação sobre como o novo objetivo é
            compatível com o objetivo original, deverá entrar em contato conosco
            em: contato@institutodacrianca.org.br.
          </p>
          <p>
            <strong>
              8. Direitos dos titulares dos dados do hotsite “Doar Felicidade”:
            </strong>
          </p>
          <p>
            8..1. O USUÁRIO tem os seguintes direitos sob as leis de proteção de
            dados em relação aos seus dados pessoais:
          </p>
          <ul>
            <li>
              (i) Solicitar acesso aos seus dados pessoais. Isso permite que o
              USUÁRIO receba uma cópia dos dados pessoais que mantemos sobre ele
              e verifique se estamos processando legalmente.
            </li>
            <li>
              (ii) Solicitar correção dos dados pessoais que mantemos sobre o
              USUÁRIO. Isso permite que ele corrija todos os dados incompletos
              ou imprecisos que mantemos.
            </li>
            <li>
              (iii) Solicitar apagar os seus dados, a menos que seja proibido
              por lei.
            </li>
            <li>
              (iv) Retirar o consentimento a qualquer momento em que confiamos
              no consentimento para processar os seus dados pessoais. Se o
              USUÁRIO retirar seu consentimento, talvez não possamos fornecer
              certas informações ou serviços a ele.
            </li>
            <li>
              (v) O USUÁRIO não terá que pagar uma taxa para acessar seus dados
              pessoais ou para exercer qualquer um dos outros direitos
              estabelecidos acima.
            </li>
            <li>
              (vi) Podemos precisar solicitar informações específicas do USUÁRIO
              para nos ajudar a confirmar sua identidade e garantir seu direito
              de acessar seus dados pessoais. Esta é uma medida de segurança
              para garantir que os dados pessoais não sejam divulgados a
              qualquer pessoa que não tenha o direito de recebê-los.
            </li>
            <li>
              (vii) Para exercer seus direitos, entre em contato conosco em:
              contato@institutodacrianca.org.br.
            </li>
          </ul>
          <p>
            <strong>9. Como Armazenamos os Dados e Registros</strong>
          </p>
          <p>
            9.1. Os dados coletados e os registros de atividades serão
            armazenados em ambiente seguro e controlado e descartados após o
            período da campanha.
          </p>
          <p>
            <strong>10. Cookies</strong>
          </p>
          <p>
            10.1. Para facilitar o uso do hotsite “Doar Felicidade”, são
            utilizados “Cookies”. Cookies são pequenas unidades de dados
            armazenadas no disco rígido do seu computador pelo seu navegador e
            que são necessárias para o uso site.
          </p>
          <p>
            10.2 Os Cookies permitem que uma Plataforma Digital memorize
            informações sobre a visita do USUÁRIO, o que consideramos relevantes
            para tornar a experiência muito mais eficiente. Esses Cookies também
            serão usados para ajudar a determinar a utilidade, interesse e o
            número de utilizações dos sites, permitindo uma navegação mais
            rápida e eficiente e eliminando a necessidade de introduzir
            repetidamente as mesmas informações.
          </p>
          <p>
            10.3. Os Cookies não armazenam dados pessoais nem coletam
            informações de identificação pessoal.
          </p>
          <p>
            10.4. Para prevenir que os Cookies sejam armazenados no seu disco
            rígido e/ou eliminá-los, o USUÁRIO poderá alterar as configurações
            para bloquear o uso de Cookies ou alertá-lo quando um Cookie estiver
            sendo enviado para seu dispositivo. A maioria dos navegadores de
            Internet são configurados para aceitar automaticamente os Cookies.
          </p>
          <p>
            10.5. Depois de autorizar o uso de Cookies, o USUÁRIO pode sempre
            desativar parte ou a totalidade dos nossos Cookies.
          </p>
          <p>
            10.6. Todos os browsers permitem ao utilizador aceitar, recusar ou
            apagar Cookies, nomeadamente através da seleção das definições
            apropriadas no respectivo navegador. O USUÁRIO pode configurar os
            Cookies no menu “opções” ou “preferências” do seu browser.
          </p>
          <p>
            10.7. Note-se que, ao desativar Cookies, isso pode impedir que
            alguns serviços da web funcionem corretamente, afetando, parcial ou
            totalmente, a navegação no website.
          </p>
          <p>
            10.8. Os dados armazenados não serão analisados pelo Google
            Analytics.
          </p>
          <p>
            <strong>11. Disposições Gerais</strong>
          </p>
          <p>
            11.1. Não utilizamos nenhum tipo de decisão automatizada que impacte
            o USUÁRIO.
          </p>
          <p>
            11.2. Temos o direito de alterar o teor desta Política de
            Privacidade a qualquer momento, conforme a finalidade ou
            necessidade, tal qual para adequação e conformidade legal de
            disposição de lei ou norma que tenha força jurídica equivalente,
            cabendo ao USUÁRIO verificá-la sempre que efetuar ao hotsite.
          </p>
          <p>
            11.3 Em caso de qualquer dúvida com relação às disposições
            constantes desta Política de Privacidade, o USUÁRIO poderá entrar em
            contato por meio dos canais de atendimento apontados a seguir:
            contato@institutodacrianca.org.br
          </p>
          <p>
            11.4. Caso empresas terceirizadas realizem o processamento de
            quaisquer dados que coletamos, as mesmas deverão respeitar as
            condições aqui estipuladas e nossas normas de Segurança da
            Informação, obrigatoriamente.
          </p>
          <p>
            11.5. Caso alguma disposição desta Política de Privacidade seja
            considerada ilegal ou ilegítima por autoridade da localidade em que
            resida ou da sua conexão à Internet, as demais condições
            permanecerão em pleno vigor e efeito.
          </p>
          <p>
            <strong>12. Lei Aplicável e Jurisdição</strong>
          </p>
          <p>
            12.1. A presente Política de Privacidade será regida e interpretada
            segundo a legislação brasileira, no idioma português, sendo eleito o
            Foro da Comarca de domicílio do USUÁRIO para dirimir qualquer
            litígio ou controvérsia envolvendo o presente documento, salvo
            ressalva específica de competência pessoal, territorial ou funcional
            pela legislação aplicável.
          </p>
          <p>28 de novembro de 2022.</p>
        </div>
      </Layout>
      <Footer />
    </>
  );
};
