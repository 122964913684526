/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { Home } from './pages/Home';
import { Donation } from './pages/Donation';
import { PrivacyPolicy } from './pages/PrivacyPolicy';

const router = createBrowserRouter([
  {
    path: '/',
    Component: Home,
  },
  {
    path: '/politica-de-privacidade',
    Component: PrivacyPolicy,
  },
  {
    path: '/doar/:id',
    Component: Donation,
  },
]);

export const Routes: React.FC = () => {
  return <RouterProvider router={router} />;
};
