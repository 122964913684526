import { useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled('div')`
  padding: 0px;
  padding-top: 32px;
  background-color: #fff2e3;
  padding-left: 20vw;
  padding-right: 20vw;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }
`;
