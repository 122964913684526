import React from 'react';
import { Typography } from '@mui/material';
import LogoIC from '../../assets/images/logo-ic-apoiador.png';
import LogoGSK from '../../assets/images/logo-gsk-apoiador.png';
import BannerFooter from '../../assets/images/Footer.png';

import { Container } from './styles';

export const Footer: React.FC = () => {
  return (
    <Container>
      <img src={BannerFooter} alt="Banner-footer" style={{ width: '100%' }} />
    </Container>
  );
};
