import {
  Avatar,
  capitalize,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { KidInterface } from '../../interfaces/kids';
import { getKidImage } from '../../utils/AuxiliarFunctions';

interface KidCardInterface {
  kid: KidInterface;
  index: number;
}

export const KidCard: React.FC<KidCardInterface> = ({ kid, index }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  const handleKidClick = () => {
    window.scrollTo(0, 0);
    navigate(`/doar/${kid.id}`);
  };

  return (
    <Grid item xs={isMobile ? 12 : 3} key={kid.nome}>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        alignItems="center"
        style={{ paddingTop: '20px', cursor: 'pointer' }}
        onClick={handleKidClick}
      >
        {kid.nome_doador ? (
          <Box
            bgcolor="#5178F6"
            style={{ borderRadius: '10px 10px 0px 0px' }}
            padding="3px 15px 0px 15px"
          >
            <Typography fontSize="14px" color="white">
              Adotado!
            </Typography>
          </Box>
        ) : (
          <Box
            style={{ borderRadius: '10px 10px 0px 0px', height: '24px' }}
            padding="3px 15px 0px 15px"
          />
        )}
        <img
          alt={kid.nome}
          src={getKidImage(kid)}
          style={{
            width: '128px',
            height: '128px',
            borderRadius: '10%',
            border: '4px solid #5178F6',
          }}
        />
        <Typography
          fontSize="16px"
          fontWeight={700}
          textTransform="uppercase"
          color="#074F21"
          paddingTop={1}
        >
          {kid.nome} | {kid.idade} anos
        </Typography>
      </Box>
    </Grid>
  );
};
