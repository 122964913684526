/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BackButton } from '../../components/BackButton';
import { Footer } from '../../components/Footer';
import { Layout } from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { KidInterface } from '../../interfaces/kids';

import {
  convertToCPFMask,
  checkCPF,
  getKidImage,
} from '../../utils/AuxiliarFunctions';

import QRCode80 from '../../assets/qrcodes/80reais-fundo-transparente.png';
import QRCode160 from '../../assets/qrcodes/160reais-fundo-transparente.png';
import QRCode240 from '../../assets/qrcodes/240reais-fundo-transparente.png';
import moedaLocal from '../../assets/qrcodes/moeda-local.png';
import { api } from '../../services/api';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import DoarFelicidadeImage from '../../assets/images/doar-felicidade-completo.png';

export const Donation: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [kid, setKid] = useState<KidInterface>({} as KidInterface);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [nameField, setNameField] = useState('');
  const [mailField, setMailField] = useState('');
  const [cpfField, setCpfField] = useState('');
  const [formaPagamento, setFormaPagamento] = useState('pix');
  const [donationAmount, setDonationAmount] = useState('');
  const [doacao, setDoacao] = useState('none');
  const navigate = useNavigate();

  const handleChangePagamento = (event: SelectChangeEvent) => {
    setFormaPagamento(event.target.value as string);
    setDonationAmount(''); // Reset donation amount when payment method changes
  };

  const handleChangeDonationAmount = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDonationAmount(event.target.value);
  };

  const [error, setError] = useState<{
    name?: string;
    mail?: string;
    cpf?: string;
    donationAmount?: string;
  }>({});

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get(`/getKidById/${id}`, {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      });
      setKid(response.data);
    } catch (err) {
      console.error('Error fetching kid data:', err);
      // Handle error accordingly, perhaps set an error state
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useDocumentTitle(kid.nome ?? 'Doação');

  const renderFormControlLabels = () => {
    switch (donationAmount) {
      case '80':
        return (
          <Grid
            item
            xs={isMobile ? 12 : 3}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img src={QRCode80} alt="qrcode_80" height={250} width={250} />
          </Grid>
        );
      case '160':
        return (
          <Grid
            item
            xs={isMobile ? 12 : 3}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img src={QRCode160} alt="qrcode_160" height={250} width={250} />
          </Grid>
        );
      case '240':
        return (
          <Grid
            item
            xs={isMobile ? 12 : 3}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img src={QRCode240} alt="qrcode_240" height={250} width={250} />
          </Grid>
        );
      default:
        return (
          <Grid
            item
            xs={isMobile ? 12 : 3}
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              src={moedaLocal}
              alt="qrcode_QualquerValor"
              height={250}
              width={250}
            />
          </Grid>
        );
    }
  };

  const renderTextDoacao = () => {
    switch (doacao) {
      case 'none':
        return (
          <>
            <Typography
              color="#074F21"
              fontFamily="Poppins"
              fontSize="18px"
              marginTop={2}
            >
              PREENCHA SEUS DADOS
            </Typography>

            <Typography
              fontFamily="Poppins"
              fontSize="18px"
              marginTop={2}
              color="#074F21"
              marginBottom={4}
              textAlign="center"
            >
              <br />
              Siga as instruções para efetuar a sua doação. <br />
              Em caso de dúvidas, envie um email para
              natal@institutodacrianca.org.br
            </Typography>
          </>
        );
      case 'pix':
        return (
          <>
            <Typography
              color="#074F21"
              fontFamily="Poppins"
              fontSize="18px"
              marginTop={2}
            >
              Obrigado por me escolher.
            </Typography>

            <Typography
              fontFamily="Poppins"
              fontSize="18px"
              marginTop={2}
              color="#074F21"
              marginBottom={4}
              textAlign="center"
            >
              Agora entre no app do seu banco, acesse a opção PIX e escaneie o
              QR code.
              <br /> Em caso de dúvidas, envie um e-mail para
              natal@institutodacrianca.org.br
            </Typography>
          </>
        );
      case 'brandt':
        return (
          <>
            <Typography
              color="#074F21"
              fontFamily="Poppins"
              fontSize="18px"
              marginTop={2}
            >
              Obrigado por me escolher.
            </Typography>

            <Typography
              fontFamily="Poppins"
              fontSize="18px"
              marginTop={2}
              color="#074F21"
              marginBottom={4}
              textAlign="center"
            >
              Entraremos em contato para realizar a transação em BRENTS.
            </Typography>
          </>
        );
      default:
        return null;
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const errors: {
      name?: string;
      mail?: string;
      cpf?: string;
      donationAmount?: string;
    } = {};

    if (nameField.length < 3) {
      errors.name = 'Preencha ao menos 3 caracteres';
    }

    if (cpfField) {
      const isValidCPF = checkCPF(cpfField);

      if (!isValidCPF) {
        errors.cpf = 'Digite os cinco primeiros dígitos do CPF';
      }
    }

    if (mailField === '') {
      errors.mail = 'Campo obrigatório';
    }

    if (!donationAmount) {
      errors.donationAmount = 'Selecione um valor de doação';
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }

    try {
      setFormLoading(true);

      const response = await api.post<{ message: string; data: KidInterface }>(
        '/adoptKid',
        {
          kid_id: Number(id),
          nome: nameField,
          email: mailField,
          cpf: cpfField,
          valor_doado: donationAmount,
          tipo_doacao: formaPagamento,
        },
      );

      setKid(response.data.data);

      if (formaPagamento === 'pix') {
        setDoacao('pix');
      } else {
        setDoacao('brandt');
      }
    } catch (err) {
      console.error('Error submitting donation:', err);
      // Handle error accordingly
    } finally {
      setFormLoading(false);
    }
  };

  const renderDoacao = () => {
    if (formLoading) {
      return <Loading />;
    }

    if (kid.nome_doador) {
      return (
        <>
          <Card
            variant="outlined"
            style={{
              backgroundColor: '#5B77F8',
              padding: 24,
              borderRadius: '20px',
              justifyItems: 'center',
            }}
          >
            {renderFormControlLabels()}
          </Card>

          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingTop={4}
              paddingBottom={12}
            >
              <BackButton
                valueText="VOLTAR PARA TODAS AS CRIANÇAS"
                closeButton={false}
              />
            </Box>
          </div>
        </>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <Card
          variant="outlined"
          style={{
            backgroundColor: '#5B77F8',
            padding: 24,
            borderRadius: '20px',
            justifyItems: 'center',
            display: 'flex',
          }}
        >
          <Box
            display="flex"
            flex={1}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={isMobile ? 300 : 700}
          >
            <TextField
              name="nome"
              label="Nome Completo"
              autoComplete="off"
              value={nameField}
              onChange={e => {
                setError({});
                setNameField(e.target.value);
              }}
              variant="filled"
              fullWidth
              margin="dense"
              InputProps={{
                disableUnderline: true,
                style: {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'white',
                  textAlign: 'center',
                  width: '200%',
                },
              }}
              sx={{
                '& .MuiFilledInput-root': {
                  backgroundColor: '#FE9003 !important',
                  borderRadius: '40px',
                  textAlign: 'center',
                },
                '& .MuiInputLabel-root': {
                  backgroundColor: '#FE9003 !important',
                  borderRadius: '40px',
                  textAlign: 'center',
                  width: '100%',
                },
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                },
              }}
              error={!!error?.name}
              helperText={error?.name}
            />
            <TextField
              name="email"
              label="Seu e-mail da PRIO (xxx@prio3.com.br)"
              type="email"
              autoComplete="off"
              value={mailField}
              onChange={e => {
                setError({});
                setMailField(e.target.value);
              }}
              variant="filled"
              fullWidth
              margin="dense"
              InputProps={{
                disableUnderline: true,
                style: {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'white',
                  textAlign: 'center',
                  width: '180%',
                },
              }}
              sx={{
                '& .MuiFilledInput-root': {
                  backgroundColor: '#FE9003 !important',
                  borderRadius: '40px',
                  textAlign: 'center',
                },
                '& .MuiInputLabel-root': {
                  backgroundColor: '#FE9003',
                  borderRadius: '20px',
                  textAlign: 'center',
                  width: '100%',
                },
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                },
              }}
              error={!!error?.mail}
              helperText={error?.mail}
            />

            <TextField
              name="cpf"
              label="5 primeiros dígitos do CPF"
              autoComplete="off"
              value={cpfField}
              onChange={e => {
                setError({});
                setCpfField(convertToCPFMask(e.target.value));
              }}
              variant="filled"
              fullWidth
              margin="dense"
              InputProps={{
                disableUnderline: true,
                style: {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: {
                  color: 'white',
                  textAlign: 'center',
                  width: '200%',
                },
              }}
              sx={{
                '& .MuiFilledInput-root': {
                  backgroundColor: '#FE9003 !important',
                  borderRadius: '40px',
                  textAlign: 'center',
                },
                '& .MuiInputLabel-root': {
                  backgroundColor: '#FE9003',
                  borderRadius: '40px',
                  textAlign: 'center',
                  width: '100%',
                },
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                },
              }}
              error={!!error?.cpf}
              helperText={error?.cpf}
            />

            <FormControl
              fullWidth
              variant="filled"
              margin="dense"
              sx={{
                backgroundColor: '#FE9003',
                borderRadius: '40px',
                '& .MuiInputBase-root': {
                  textAlign: 'center',
                },
                '& .MuiSelect-select': {
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                '& .MuiInputLabel-root': {
                  textAlign: 'center',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: 'max-content',
                },
              }}
            >
              <InputLabel
                id="forma-pagamento-label"
                style={{
                  color: 'white',
                }}
              >
                Forma de Pagamento
              </InputLabel>
              <Select
                labelId="forma-pagamento-label"
                id="forma-pagamento-select"
                value={formaPagamento}
                onChange={handleChangePagamento}
                disableUnderline
                style={{
                  color: 'white',
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: '#FE9003',
                      color: 'white',
                      borderRadius: '10px',
                    },
                  },
                }}
              >
                <MenuItem value="pix" style={{ textAlign: 'center' }}>
                  PIX
                </MenuItem>
                <MenuItem value="brandt" style={{ textAlign: 'center' }}>
                  Brents
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl error={!!error?.donationAmount}>
              <RadioGroup
                row
                aria-labelledby="donation-amount-group-label"
                name="donation-amount-group"
                style={{ marginTop: '20px' }}
                value={donationAmount}
                onChange={handleChangeDonationAmount}
              >
                {formaPagamento === 'pix' ? (
                  <>
                    <FormControlLabel
                      value="80"
                      sx={{
                        color: 'white',
                      }}
                      control={
                        <Radio
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#FE9003',
                            },
                          }}
                        />
                      }
                      label="R$80,00"
                    />
                    <FormControlLabel
                      value="160"
                      sx={{
                        color: 'white',
                      }}
                      control={
                        <Radio
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#FE9003',
                            },
                          }}
                        />
                      }
                      label="R$160,00"
                    />
                    <FormControlLabel
                      value="240"
                      sx={{
                        color: 'white',
                      }}
                      control={
                        <Radio
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#FE9003',
                            },
                          }}
                        />
                      }
                      label="R$240,00"
                    />
                  </>
                ) : (
                  <>
                    <FormControlLabel
                      value="b15"
                      sx={{
                        color: 'white',
                      }}
                      control={
                        <Radio
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#FE9003',
                            },
                          }}
                        />
                      }
                      label="15,000 brents"
                    />
                    <FormControlLabel
                      value="b20"
                      sx={{
                        color: 'white',
                      }}
                      control={
                        <Radio
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#FE9003',
                            },
                          }}
                        />
                      }
                      label="20,000 brents"
                    />
                    <FormControlLabel
                      value="b30"
                      sx={{
                        color: 'white',
                      }}
                      control={
                        <Radio
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: '#FE9003',
                            },
                          }}
                        />
                      }
                      label="30,000 brents"
                    />
                  </>
                )}
              </RadioGroup>
              {error.donationAmount && (
                <Typography color="error" variant="caption">
                  {error.donationAmount}
                </Typography>
              )}
            </FormControl>
          </Box>
        </Card>
        <Box
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{
              backgroundColor: '#D66CB7',
              width: '320px',
              marginTop: '30px',
              borderRadius: '20px',
              fontWeight: 'bold',
              fontSize: '20px',
              marginBottom: '20px',
            }}
          >
            Confirmar
          </Button>
          {/* <Typography color="#074F21" style={{ marginTop: 10 }}>
            Ao confirmar a doação você está de acordo com as nossas ‎
            <Link to="/politica-de-privacidade">políticas de privacidade</Link>
          </Typography> */}
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingTop={2}
              paddingBottom={4}
            >
              <ArrowBackIcon
                style={{
                  backgroundColor: '#FE9003',
                  color: 'white',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  padding: '5px',
                  marginRight: '10px',
                  marginBottom: '-15px',
                }}
              />
              <BackButton
                valueText="VOLTAR PARA TODAS AS CRIANÇAS"
                closeButton={false}
              />
            </Box>
          </div>
        </Box>
      </form>
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="column" flex={1} alignItems="center">
        <a href="/">
          <img
            width="100%"
            src={DoarFelicidadeImage}
            alt="DoarFelicidadeImage"
          />
        </a>
      </Box>
      <Layout>
        {loading ? (
          <Loading />
        ) : (
          <Box>
            <Box
              id="form-adocao"
              display="flex"
              flexDirection={isMobile ? 'column-reverse' : 'row'}
              justifyContent={isMobile ? 'center' : 'space-between'}
              alignItems={isMobile ? 'center' : 'flex-start'}
              marginBottom={4}
              marginTop={4}
            >
              <Box />
              <img
                alt={kid.nome}
                src={getKidImage(kid)}
                style={{
                  width: '300px',
                  height: '300px',
                  borderRadius: '10%',
                  border: '8px solid #5178F6',
                  marginRight: isMobile ? '0px' : '-120px',
                }}
              />
              <BackButton valueText="VOLTAR" closeButton />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                fontFamily="Poppins"
                color="#074F21"
                fontWeight={700}
                variant="h3"
              >
                {kid.nome}
              </Typography>
              {renderTextDoacao()}
            </Box>

            {renderDoacao()}
          </Box>
        )}
      </Layout>
      <Footer />
    </>
  );
};
