import { Button, Typography, useMediaQuery } from '@mui/material';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface Props {
  valueText: string;
  closeButton: boolean;
}

export const BackButton: React.FC<Props> = ({ valueText, closeButton }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <div
      onClick={() => {
        navigate('/');
      }}
      role="button"
      style={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        marginTop: 16,
        cursor: 'pointer',
        marginBottom: isMobile ? '20px' : '0px',
      }}
    >
      <Typography
        fontFamily="Poppins"
        style={{ textDecoration: 'underline', opacity: '0.7' }}
        variant="h6"
        marginRight={1}
      >
        {valueText}
      </Typography>
      {closeButton ? (
        <Button
          style={{
            fontSize: '18px',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            color: 'white',
            padding: '4px',
            fontWeight: 'bold',
            backgroundColor: '#FE9003',
            minWidth: 'unset',
          }}
        >
          x
        </Button>
      ) : null}
    </div>
  );
};
