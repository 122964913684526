import { KidInterface } from '../interfaces/kids';

export const getKidImage = (kid: KidInterface): string => {
  return `/avatares/${kid.id}.png`;
};

export const convertToCPFMask = (value: string): string => {
  const numericValue = value.replace(/\D/g, '');

  const limitedValue = numericValue.slice(0, 5);

  return limitedValue.replace(/(\d{3})(\d{1,2})/, '$1.$2');
};

export const sanitizeString = (value: string): string => {
  return value.replace(/\D/g, '');
};

export const checkCPF = (cpfField: string): boolean => {
  const insertedCPF = sanitizeString(cpfField);
  if (insertedCPF === '00000') {
    return false;
  }
  if (insertedCPF.length !== 5) {
    return false;
  }
  return true;
};
